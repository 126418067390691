import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is Scenario Solving?`}</h2>
    <p>{`A scenario is typically just one narrow manifestation of a much broader problem.
Scenario solving is taking one narrow segment of a problem and solving it
without considering the scope or context of the problem itself.`}</p>
    <h2>{`What is Problem Solving?`}</h2>
    <p>{`Problem-solving is considering the entire scope of the problem and implementing
a solution. A problem can be thought of as an aggregate of scenarios, the
solution to which resolves the majority of the problem's scenarios. A scenario
is typically just one manifestation of a much more general problem.`}</p>
    <h2>{`The Difference`}</h2>
    <p>{`Scenario solving results in a fragmented user experience. While problem-solving
results in useful primitives that can be composed to build features
significantly faster and more efficiently.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      